function secondsToHms(d: number) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + "時間" : "";
  var mDisplay = m > 0 ? m + "分" : "";
  var sDisplay = s > 0 ? s + "秒" : "";
  return hDisplay + mDisplay + sDisplay; 
}

export default secondsToHms;
