import React from "react";
import styled from "styled-components";
import { Price } from "./components";
import PurchaseButton from "components/PurchaseButton";
import { useSelector } from "react-redux";
import numberWithComma from "utils/numberWithComma";

const BottomButtonContainer = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 60px;
  padding: ${({ theme: { spacing } }) => spacing(1)}px
    ${({ theme: { spacing } }) => spacing(2)}px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
`;

const Bottombar = ({ skuId }: { skuId: string }) => {
  const sku = useSelector((state) => state.skus[skuId]);

  if (!sku) return null;

  return (
    <BottomButtonContainer>
      <Price>¥{numberWithComma(sku.price)}</Price>
      <PurchaseButton skuId={skuId} />
    </BottomButtonContainer>
  );
};

export default Bottombar;
