import { useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { analytics } from "utils/firebase";

const Analytics = () => {
  const history = useHistory();

  useEffect(() => {
    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      //@ts-ignore
      analytics().logEvent("page_view", { page_path: location.pathname });
    });
  }, [history]);

  return null;
};

export default Analytics;
