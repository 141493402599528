import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Section from "./Section";

const Sections = ({ visible = true }) => {
  const { skuId } = useParams();

  const sectionIds = useSelector((state) =>
    Object.values(state.sections)
      .filter((sec) => sec.skuId === skuId)
      .sort((a, b) => a.index - b.index)
      .map((sec) => sec.id)
  );

  if (!visible) return null;

  return (
    <>
      {sectionIds.map((sectionId) => (
        <Section key={sectionId} sectionId={sectionId} />
      ))}
    </>
  );
};

export default Sections;
