import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Main from "layouts/Main";
import Page404 from "pages/Page404";
import Landing from "pages/Landing";
import CourseDetail from "pages/CourseDetail";
import Cart from "pages/Cart";
import Lecture from "pages/Lecture";
import Modal from "components/Modal";
import LoginCard from "components/LoginCard";
import useModal from "hooks/useModal";
import ReactGA from "react-ga";
import Analytics from "components/Analytics";
import ScrollToTop from "components/ScrollToTop";
import Meta from "components/Meta";
import RedirectLecture from "pages/RedirectLecture";
import CheckoutCallback from "pages/CheckoutCallback";

ReactGA.initialize("G-XMF69F6ESE");
ReactGA.pageview("test");

type RouteType = {
  path: string;
  component: React.FC<any>;
};

type Routes = {
  [key: string]: RouteType;
};

const mainRoutes: Routes = {
  landing: {
    path: "/",
    component: Landing,
  },
  courseDetail: {
    path: "/courses/:skuId",
    component: CourseDetail,
  },
  lecture: {
    path: "/courses/:skuId/lectures/:lectureId",
    component: Lecture,
  },
  cart: {
    path: "/cart",
    component: Cart,
  },
  redirectLecture: {
    path: "/courses/:skuId/lectures",
    component: RedirectLecture,
  },
  checkoutCallback: {
    path: "/callback/checkout",
    component: CheckoutCallback,
  },
};

const configureRoutes = (Layout: React.FC, routes: Routes) => {
  return Object.values(routes).map(({ component: Component, path }, index) => (
    <Route
      key={index}
      path={path}
      exact
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ));
};

const Routes = () => {
  const [{ show, meta }, setShow] = useModal("login");
  return (
    <Router>
      <ScrollToTop />
      <Meta />
      <Switch>
        {configureRoutes(Main, mainRoutes)}
        <Route render={() => <Page404 />} />
      </Switch>
      <Modal show={show} setShow={setShow} title="Login/Signup">
        <LoginCard type={meta?.type} />
      </Modal>
      <Analytics />
    </Router>
  );
};

export default Routes;
