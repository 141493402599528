import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Sku } from "redux/reducers/skus";
import Container from "templates/Container";
import media from "utils/media";
import Heading from "components/Heading";
import { Rating } from "components/Rating";

const List = styled.ul`
  display: flex;
  ${media.spsm`
    flex-wrap: wrap;
  `}
  ${media.sp`
    flex-wrap: wrap;
  `}
`;
const Item = styled.li`
  display: flex;
  flex-direction: column;
  width: calc((100% - 72px) / 4);
  box-shadow: 0 0 1px 1px rgba(20,23,28,.1), 0 3px 1px 0 rgba(20,23,28,.1);
  margin-right: 24px;
  &:nth-child(4n) {
    margin-right: 0;
  }
  ${media.spsm`
    width: calc((100% - 48px) / 3);
    &:nth-child(4n) {
      margin-right: 24px;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(3n + 4), &:nth-child(3n + 5), &:nth-child(3n + 6) {
      margin-top: 24px;
    }
  `}
  ${media.sp`
    width: calc((100% - 24px) / 2);
    &:nth-child(4n) {
      margin-right: 24px;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(2n + 3), &:nth-child(2n + 4) {
      margin-top: 24px;
    }
  `}
  ${media.xs`
    width: 100%;
    margin-right: 0;
    &:not(:first-child) {
      margin-top: 24px;
    }
  `}
`;
const Thumbnail = styled.div`
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: 61.25%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
const Content = styled.div`
  flex-grow: 1;
  padding: 16px;
`;
const Title = styled.h3`
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  line-height: 1.4;
  margin-bottom: 4px;
`;
const Producer = styled.p`
  font-size: 1.2rem;
  color: #999;
  margin-bottom: 1rem;
`;
const Price = styled.p`
  font-size: 2rem;
  font-weight: bold;
  text-align: right;
`;

type Props = {
  courses: Sku[];
};
const CourseLineup: React.FC<Props> = (props) => {
  return (
    <Container id="courses">
      <Heading text="Course Lineup" />
      <List>
        {props.courses.map((course) => (
          <Item key={course.id}>
            <Link to={`/courses/${course.id}`}>
              <Thumbnail>
                <img src={course.imageUrl} alt={course.title} />
              </Thumbnail>
              <Content>
                <Title>{course.title}</Title>
                <Producer>{course.producer}</Producer>
                <Rating
                  value={course.rating.value}
                  count={course.rating.count}
                />
                <Price>{`￥${course.price.toLocaleString()}`}</Price>
              </Content>
            </Link>
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default CourseLineup;
