import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { TOP_IMAGE_SP, TOP_IMAGE_DESKTOP } from "constants/index";

const imageUrls = {
  top: {
    sp: TOP_IMAGE_SP,
    desktop: TOP_IMAGE_DESKTOP,
  },
};

type Keys = keyof typeof imageUrls;

const useOptimizedImage = (key: Keys) => {
  const theme = useTheme();
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  return isUpSm ? imageUrls[key].desktop : imageUrls[key].sp;
};

export default useOptimizedImage;
