import React from "react";
import { useSelector } from "react-redux";
import { Hidden } from "@material-ui/core";
import { useParams } from "react-router-dom";

import MainImage from "./MainImage";
import Purchase from "./Purchase";
import Summary from "./Summary";
import { Container, Inner, ContentContainer, Main } from "./components";
import Bottombar from "./Bottombar";
import CourseSections from "./CourseSections";
import Page404 from "../Page404";
import Helmet from "react-helmet";
import Description from "./Description";

const CourseDetail = () => {
  const { skuId } = useParams();
  const sku = useSelector((state) => skuId && state.skus[skuId]);

  if (skuId === undefined || !sku) return <Page404 />;

  return (
    <Container>
      <Helmet
        script={[
          {
            type: 'application/ld+json',
            innerHTML: (JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              'name': sku.title,
              'description': sku.description,
              'url': 'https://atsueigo.online/',
              'logo': 'https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fatsueigo-online.svg?alt=media&token=82bff8cb-2ffd-48a1-bc10-367c59fdfdd8',
            }))
          },
          {
            type: 'application/ld+json',
            innerHTML: (JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Product',
              'name': sku.title,
              'description': sku.description,
              'offers': {
                '@type': 'Offer',
                'price': sku.price,
                'availability': 'InStock',
                'priceCurrency': 'JPY',
              }
            }))
          }
        ]}
      >
        <title>{`Atsueigo | ${sku.title}`}</title>
        <meta name="description" content={sku.description.slice(0, 100)} />
        <meta property="og:title" content={`Atsueigo | ${sku.title}`} />
        <meta property="og:description" content={sku.description.slice(0, 100)} />
        <meta name="twitter:title" content={`Atsueigo | ${sku.title}`} />
        <meta name="twitter:description" content={sku.description.slice(0, 100)} />
      </Helmet>
      <Inner>
        <Main>
          <MainImage img={sku.imageUrl} />
          <ContentContainer>
            <Hidden smUp>
              <Purchase skuId={skuId} />
            </Hidden>
            <Summary skuId={skuId} />
            <Description skuId={skuId} />
            <CourseSections />
          </ContentContainer>
        </Main>
        <Hidden smDown>
          <Purchase skuId={skuId} />
        </Hidden>
        <Hidden smUp>
          <Bottombar skuId={skuId} />
        </Hidden>
      </Inner>
    </Container>
  );
};

export default CourseDetail;
