import React from "react";
import styled from "styled-components";
import media from "utils/media";

const Container = styled.div<{ aspectRatio: number }>`
  position: relative;
  flex-basis: 66.66%;
  margin-bottom: 48px;

  &:before {
    content: "";
    display: block;
    padding-top: ${({ aspectRatio = 0.5 }) => aspectRatio * 100}%;
  }

  ${media.sp`
    margin-bottom: 16px;
  `}
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const MainImage = ({
  aspectRatio = 0.5,
  img,
}: {
  img: string;
  aspectRatio?: number;
}) => {
  return (
    <Container aspectRatio={aspectRatio}>
      <Image src={img} alt="main" />
    </Container>
  );
};

export default MainImage;
