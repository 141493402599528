import React from "react"
import styled from "styled-components"

const Text = styled.h2`
  font-size: 2.4rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
`

type Props = {
  text: string,
}
const Heading: React.FC<Props> = ({ text }) => {
  return <Text>{text}</Text>
}

export default Heading
