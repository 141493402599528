export const TRANSACTION_LAW_URL =
  "https://docs.google.com/document/d/e/2PACX-1vSTeK0Njw6aGGqXaSwjGPp1g3EeJ_AmMsSl_pp9XSKdmt-4OeyZeRwkGaWEULzxEUTn4q0erGZgJZJT/pub";
export const PRIVACY_POLICY_URL =
  "https://docs.google.com/document/d/e/2PACX-1vRPNXP8Vaqi2ugD_vfhG5b2wtMQ5lpTZlbkOg6zwaIvJuG25xzF6u72G70q9sUJl1M6LpeLQH9vReMq/pub";
export const TOS_URL =
  "https://docs.google.com/document/d/e/2PACX-1vQD5roG3F6komGk41NihQGKawUAE59mxuCtDs68LtVeA-Rysr_SlqMYbuUa98hxK2NEZqUsMSfAUkmF/pub";
export const TOP_IMAGE_SP =
  "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Ftop_mobile.jpg?alt=media&token=48dd79f1-d169-481c-8f6c-d5799c778810";
export const TOP_IMAGE_DESKTOP =
  "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Ftop_desktop.jpg?alt=media&token=8620581c-2b35-47c5-8b6c-5b6b75f2df89";
export const FAQ_URL =
  "https://docs.google.com/document/d/e/2PACX-1vSXBPK6byKj1Y4KCXnKX196abuxLxiJssM7I-zwsjAwLYstEf0m6_BlHHDw8hK7YtmlKyo_CPjL78Qn/pub";
export const CONTACT_URL = "https://thebase.in/inquiry/atsueigo-base-shop";
