import styled from "styled-components";
import media from "utils/media";

export const ContentTitle = styled.div`
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Container = styled.main`
  display: flex;
`;

export const Inner = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 48px 48px;
  display: flex;
  margin: auto;
  align-items: flex-start;
  justify-content: space-between;
  ${media.sp`
    flex-direction: column;
    padding: 0;
  `}
`;

export const Main = styled.div`
  flex-basis: 55%;

  ${media.sp`
    flex-basis: 100%;
  `}
`;

export const ContentContainer = styled.div`
  padding: 0;

  > * {
    margin-bottom: 48px;
  }

  ${media.sp`
    padding: 0 16px;
  `}
`;

export const Price = styled.div`
  font-size: 1.9rem;
  margin-right: 16px;
`;
