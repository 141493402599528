import { useSelector } from "react-redux";
import { Lecture } from "redux/reducers/lectures";

type SelectorType = (lecture: Lecture[]) => any;

const useLectures = (
  sectionId: string,
  selector: SelectorType = (lectures: Lecture[]) => lectures
) => {
  return useSelector((state) => {
    const lectures =
      Object.values(state.lectures)
        .filter((lec) => lec.sectionId === sectionId)
        .sort((a, b) => {
          return a.index - b.index;
        }) || [];

    return selector(lectures);
  });
};

export default useLectures;
