import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyBvAaa5lZcXRCMk6BNGl9WEi29ryJQ8c64",
  authDomain: "atsueigo-57ccf.firebaseapp.com",
  databaseURL: "https://atsueigo-57ccf.firebaseio.com",
  projectId: "atsueigo-57ccf",
  storageBucket: "atsueigo-57ccf.appspot.com",
  messagingSenderId: "912749046148",
  appId: "1:912749046148:web:fd346aae50cee65863d5d1",
  measurementId: "G-XMF69F6ESE"
};

firebase.initializeApp(config);

export default firebase;
export const db = firebase.firestore;
export const functions = firebase.functions;
export const analytics = firebase.analytics;
