import React from "react"
import styled from "styled-components"

const Container = styled.button<{ emptyColor?: boolean }> `
  appearance: none;
  border: none;
  outline: none;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: #0e65f3;
  border-radius: 2px;
  padding: 11px 12px;
  transition-duration: 0.2s;
  &:hover {
    background-color: #0e65f399;
  }
  ${props => props.emptyColor && `
    color: #686f7a;
    background-color: #fff;
    border: solid 1px #686f7a;
    &:hover {
      color: #000;
      background-color: #fff;
      border: solid 1px #000;
    }
  `}
`

type Props = {
  children: React.ReactNode,
  clickHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  emptyColor?: boolean,
}
const Button: React.FC<Props> = ({ children, clickHandler, emptyColor }) => (
  <Container onClick={clickHandler} emptyColor={emptyColor}>
    {children}
  </Container>
)

export default Button
