import React from "react";
import styled from "styled-components";
import Container from "templates/Container";
import media from "utils/media";
import bulb from "assets/images/bulb.png";

const List = styled.ul`
  display: flex;
  ${media.sp`
    flex-wrap: wrap;
  `}
`;
const Item = styled.li`
  width: calc((100% - 48px) / 3);
  display: flex;
  background-color: #fff;
  padding: 24px;
  border: 1px solid #eee;
  border-radius: 2px;
  &:not(:last-child) {
    margin-right: 24px;
  }
  ${media.sp`
    width: 100%;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  `}
`;
const Icon = styled.div`
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin-right: 24px;
  img {
    height: 100%;
    width: 100%;
  }
`;
const Content = styled.div`
  flex-grow: 1;
`;
const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  line-height: 1;
  margin-bottom: 8px;
`;
const Description = styled.p`
  font-size: 1.6rem;
  color: #999;
  line-height: 1.4;
`;
const Value = () => (
  <Container style={{ backgroundColor: "#f2f3f5" }}>
    <List>
      <Item>
        <Icon>
          <img src={bulb} alt="value1" />
        </Icon>
        <Content>
          <Title>オンラインで学べる</Title>
          <Description>
            買い切り教材だから、自分のペースで進められる。
          </Description>
        </Content>
      </Item>
      <Item>
        <Icon>
          <img src={bulb} alt="value1" />
        </Icon>
        <Content>
          <Title>English Intelligence</Title>
          <Description>英語思考力を鍛えて、効果的な英語学習を。</Description>
        </Content>
      </Item>
      <Item>
        <Icon>
          <img src={bulb} alt="value1" />
        </Icon>
        <Content>
          <Title>選べる教材</Title>
          <Description>目的に応じて最適な教材を選ぼう。</Description>
        </Content>
      </Item>
    </List>
  </Container>
);

export default Value;
