import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  distinction1PriceId,
  distinction2PriceId,
  vocablaristPriceId,
} from "utils/config";

export type Book = {
  id: string;
  title: string;
  description: string;
  detail: string;
  producer: string;
  price: number;
  type: string;
  stripeSkuId: string;
  imageUrl: string;
  subImages: string[];
};

export type BooksState = {
  [key: string]: Book;
};

const initialState: BooksState = {
  "0": {
    id: "0",
    title: "英単語帳 Distinction 1",
    description: "Atsueigo オリジナル英単語帳 Distinction I 。",
    detail: `【英単語帳Distinction】
    ・400語収録（レベル別4段階分け）
    ・各レベルに語彙→同義語、同義語→語彙のチェックリスト付き
    ・発音記号＝IPA式発音記号
    ・発音記号、音の変化の法則解説
    ・Atsueigo式単語の覚え方解説
    ・句動詞の解説
    ・全243ページ
    ・赤シート付き
    ・音声はMP3でオンラインダウンロード可能（巻末にURLあり）

    ※巻末のURLにてMP3をダウンロードする際は、URLをブラウザの「URL入力欄」に入力してして下さい。

    作成風景や単語帳の詳細はコチラ：https://goo.gl/GycmsM

    【英単語帳第二弾 Distinction II も好評発売中！】
    Distinction I と II はそれぞれ全く別の単語が収録されております
    https://atsueigo.online/books/1

    【Distinctionを使った単語学習法について】
    英単語の学習法については書籍VOCABULARISTにて詳しく紹介しています。Distinction を効率的・効果的に学習したい方におすすめの一冊です。
    VOCABULARISTはこちら：https://atsueigo.online/books/2
    `,
    producer: "Atsu",
    price: 3500,
    type: "book",
    stripeSkuId: distinction1PriceId,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction1.png?alt=media&token=e5fc0a24-5102-4fe4-a955-adb2d9bdd425",
    subImages: [
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction1_sub1.jpg?alt=media&token=0c97f8d2-f9a2-4d72-bc41-6df48b69e895",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction1_sub2.png?alt=media&token=1d40bf6b-35a5-4948-bfd1-a052a29218dc",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction1_sub3.png?alt=media&token=ee885c72-ca7e-48e4-b058-59413032b98d",
    ],
  },
  "1": {
    id: "1",
    title: "英単語帳 Distinction 2",
    description:
      "Atsueigo オリジナル英単語帳 待望の第二弾 Distinction II 。Distinction I の単語を数多く例文で使用しているため、Distinction Iとの相乗効果も大きく期待できます。",
    detail: `【英単語帳Distinction】
    ・400語収録（レベル別4段階分け）
    ・各レベルに語彙→同義語、同義語→語彙のチェックリスト付き
    ・発音記号＝IPA式発音記号
    ・発音記号、音の変化の法則解説
    ・Atsueigo式単語の覚え方解説
    ・句動詞の解説
    ・全245ページ
    ・赤シート付き
    ・音声はMP3でオンラインダウンロード

    【英単語帳第一弾 Distinction I も好評発売中！】
    Distinction I と II はそれぞれ全く別の単語が収録されております。
    https://atsueigo.online/books/1

    【Distinctionを使った単語学習法について】
    英単語の学習法については書籍VOCABULARISTにて詳しく紹介しています。Distinction を効率的・効果的に学習したい方におすすめの一冊です。
    VOCABULARISTはこちら：https://atsueigo.online/books/2
    `,
    producer: "Atsu",
    price: 3500,
    type: "book",
    stripeSkuId: distinction2PriceId,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction2.png?alt=media&token=05b7818d-c201-4d01-8b24-e2234aa16664",
    subImages: [
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction2_sub1.png?alt=media&token=5a2a0fa2-d7dc-478e-8377-a3faf012a387",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction2_sub2.jpg?alt=media&token=a26019ab-6512-4777-90db-bdede9cf1d5d",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction2_sub3.jpg?alt=media&token=9454cb6f-5bd9-44b9-b5b6-c05e0c8473a8",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction2_sub4.jpg?alt=media&token=8cbfe58a-959a-411d-a5dc-08f0cf8a5453",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fdistinction2_sub5.png?alt=media&token=23b1e5e4-e6f9-4538-bed1-ac7ff56cc854",
    ],
  },
  "2": {
    id: "2",
    title: "英単語学習本 VOCABULARIST",
    description:
      "ATSUの英単語暗記メソッドをとことん解説したオリジナル英単語暗記本 VOCABULARIST。本書は英単語帳ではなく、英単語の学習法（覚え方など）を具体的かつ詳細に解説した書籍になります。",
    detail: `【本書の３つの特徴】
    特長１：ATSUがこれまでに行ってきた英単語学習の集大成
   ▶︎本書で紹介する学習法は、誰かの学習法の寄せ集めではなく、すべてATSU本人が長い時間と労力を割いて確立・最適化してきたもの。ATSUの思考回路をまるごとインストールできる一冊です。

    特長２：目的、目標、戦略、戦術。しっかり体系づけされた方法論
    ▶︎そもそも英語学習の目的って？なぜ単語学習が重要？具体的な学習方法は？・・・「なんとなく」ではなく、しっかりと体系づけられた方法論なので、すべてのアクションに明確な理由があるのです。

    特長３：全編にわたりイラストや図表を多用し、ヴィジュアライズ
    ▶︎全編にわたり、文章を読むだけではなかなか理解しづらい「概念」や「構造」にはイラストや図表を用意。また、巻末の『接頭語・接尾語マップ』には30個の書き下ろしイラストを収録しました。


    【その他VOCABULARIST詳細】

    ・全190ページ
    ・巻頭６ページフルカラー
    ・巻末付録「接頭語・接尾語マップ」付き


    【目次】
    Chapter 1 英単語学習の意義
    Chapter 2 英語学習の流れ
    Chapter 3 英単語学習の全体像
    Chapter 4 英単語学習の目標
    Chapter 5 英単語学習の戦略
    Chapter 6 英単語学習の 3 つの基本戦術
    Chapter 7 英単語学習の応用戦術〜構造分析
    Chapter 8 英単語学習の応用戦術〜画像(イメージ)暗記
    Chapter 9 英単語学習の応用戦術〜クロスレファレンス暗記
    Chapter 10 英単語学習の応用戦術〜語源暗記
    Appendix 巻末付録: 接頭語・接尾語マップ
    `,
    producer: "Atsu",
    price: 3000,
    type: "book",
    stripeSkuId: vocablaristPriceId,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fvocabularist.png?alt=media&token=b518fd57-9ef8-4e58-acb9-fc3bf334a7fe",
    subImages: [
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fvocabularist_sub1.png?alt=media&token=4141626e-bd7f-4f4c-aca0-2cda543ee048",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fvocabularist_sub2.jpg?alt=media&token=0f296a70-b842-4e98-8178-094011f9847a",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fvocabularist_sub3.png?alt=media&token=4c112882-0a24-49dd-96e2-f4683bfe77f6",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fvocabularist_sub4.png?alt=media&token=ce949857-fc43-471e-8ea3-18ac4b8c8a52",
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Fvocabularist_sub5.png?alt=media&token=f6293d6d-cf89-49f6-82c7-ea6ccc49b8dc",
    ],
  },
};

const booksReducer = reducerWithInitialState(initialState).default(
  (state) => state
);

export default booksReducer;
