import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import {
  postLecturesStart as postLectureStartApi,
  putLecturesEnd as putLecturesEndApi,
} from "utils/api";
import { AppState } from "../reducers";
import { UserLecture } from "../reducers/userLectures";

const actionCreator = actionCreatorFactory();

const postLecturesStartAction = actionCreator.async<
  { lectureId: string; userId: string },
  {},
  { code: number; description: string }
>("POST_LECTURES_START");

const putLecturesEndAction = actionCreator.async<
  { lectureId: string; userId: string },
  UserLecture,
  { code: number; description: string }
>("PUT_LECTURES_END");

export const postLecturesStart = (lectureId: string, userId: string) => async (
  dispatch: Dispatch<Action>
) => {
  dispatch(postLecturesStartAction.started({ lectureId, userId }));

  try {
    const result = await postLectureStartApi(lectureId, userId);

    dispatch(
      postLecturesStartAction.done({
        params: { lectureId, userId },
        result: {},
      })
    );

    return result;
  } catch (error) {
    dispatch(
      postLecturesStartAction.failed({
        params: { lectureId, userId },
        error: { code: error.code, description: error.description },
      })
    );

    return error;
  }
};

export const putLecturesEnd = (lectureId: string, userId: string) => async (
  dispatch: Dispatch<Action>,
  getState: () => AppState
) => {
  const userLecture = Object.values(getState().userLectures).filter(
    (ul) => ul.lectureId === lectureId && Boolean(ul.end)
  );

  if (Boolean(userLecture.length)) return;

  dispatch(putLecturesEndAction.started({ lectureId, userId }));

  try {
    const result = await putLecturesEndApi(lectureId, userId);

    dispatch(
      putLecturesEndAction.done({
        params: { lectureId, userId },
        result,
      })
    );

    return result;
  } catch (error) {
    dispatch(
      putLecturesEndAction.failed({
        params: { lectureId, userId },
        error: { code: error.code, description: error.description },
      })
    );

    return error;
  }
};

const userLecturesActions = {
  postLecturesStartAction,
  putLecturesEndAction,
};

export default userLecturesActions;
