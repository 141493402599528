import React from "react"
import styled from "styled-components"

const Text = styled.h3`
  font-size: 1.8rem;
  color: #999;
  margin-bottom: 8px;
`

type Props = {
  text: string,
}
const SubHeading: React.FC<Props> = ({ text }) => {
  return <Text>{text}</Text>
}

export default SubHeading
