import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { putLecturesEnd } from "redux/actions/userLectures";
import Description from "./Description";
import Sections from "./Sections";
import {
  VideoContainer,
  Player,
  Tab,
  Tabs,
  TabsContainer,
  TabContentContainer,
} from "./components";
import Page404 from "../Page404";
import { useOrdersByCourse } from "hooks/useOrders";

const useIsOrdered = (skuId?: string) => useOrdersByCourse(skuId).length > 0;

const Lecture: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const { lectureId, skuId } = useParams();
  const history = useHistory();
  const lecture = useSelector((state) =>
    lectureId ? state.lectures[lectureId] : null
  );
  const isOrdered = useIsOrdered(skuId);
  const updating = useRef(false);
  const userId = useSelector((state) => state.account?.id);
  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const dispatch = useDispatch();

  const onProgress = async ({ played }: { played: number }) => {
    if (
      played > 0.9 &&
      userId &&
      lectureId !== undefined &&
      updating.current === false
    ) {
      updating.current = true;
      await dispatch(putLecturesEnd(lectureId, userId));
      updating.current = false;
    }
  };

  useEffect(() => {
    // Take users to course detail page if they doesn't order this course
    if (isOrdered) return;
    history.push(`/courses/${skuId}`);
  }, [isOrdered, skuId, history]);

  if (!lecture || !isOrdered) return <Page404 />;

  return (
    <React.Fragment>
      <VideoContainer>
        <Player
          url={lecture.videoUrl}
          width="100%"
          height="100%"
          controls
          config={{ vimeo: { playerOptions: { playsinline: 1 } } }}
          onProgress={onProgress}
        />
      </VideoContainer>
      <TabsContainer>
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="講座内容" />
          <Tab label="概要" />
        </Tabs>
        <TabContentContainer>
          <Sections visible={tabValue === 0} />
          <Description visible={tabValue === 1} />
        </TabContentContainer>
      </TabsContainer>
    </React.Fragment>
  );
};

export default Lecture;
