import React from 'react'
import { useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';

const RedirectLecture = () => {
  const { skuId } = useParams()
  const firstLectureId = useSelector((state) => {
    const sections = Object.values(state.sections)
      ?.filter((section) => section.skuId === skuId)
    if (!Boolean(sections.length)) return null;
    const firstSection = sections.reduce((a, b) => (a.index < b.index ? a : b));
    return Object.values(state.lectures)
      ?.filter((lecture) => lecture.sectionId === firstSection.id)
      ?.reduce((a, b) => (a.index < b.index ? a : b))?.id;
  });
  return (
    <Redirect to={`/courses/${skuId}/lectures/${firstLectureId}`} />
  )
}

export default RedirectLecture;
