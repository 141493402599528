import React from "react"
import { ContentTitle } from "./components";
import Sections from "../Lecture/Sections";

const CourseSections = () => {
  return (
    <div>
      <ContentTitle>コースの内容</ContentTitle>
      <Sections />
    </div>
  );
};

export default CourseSections;
