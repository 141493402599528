import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useOrdersByCourse } from "hooks/useOrders";
import useModal from "hooks/useModal";
import checkout from "utils/checkout";
import { analytics } from "utils/firebase";

const PurchaseButton = ({ skuId }: { skuId: string }) => {
  const userId = useSelector((state) => state.account?.id);
  const sku = useSelector((state) => state.skus[skuId]);
  const coupon = useSelector((state) => {
    const coupon = state.coupon;
    return coupon ? sku.coupons[coupon] : null;
  });
  const isOrdered = useOrdersByCourse(skuId).length > 0;
  const [, setShowModal] = useModal("login");
  const history = useHistory();
  const priceId = coupon ? coupon.priceId : sku.stripeSkuId;

  const onClick = () => {
    if (!userId) {
      analytics().logEvent("click_purchase_button", {
        skuId,
        isLoggedin: false,
      });
      setShowModal(true, { type: "signup" });
      return;
    } else if (isOrdered) {
      history.push(`/courses/${skuId}/lectures`);
      return;
    }
    if (!sku) return;

    analytics().logEvent("click_purchase_button", { skuId, isLoggedin: true });
    checkout(sku.id, priceId, 1, userId, sku.type);
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      fullWidth
      size="large"
      onClick={onClick}
    >
      {isOrdered ? "コースに移動" : "レジに進む"}
    </Button>
  );
};

export default PurchaseButton;
