import React from "react";
import styled from "styled-components";

import media from "utils/media";
import useOptimizedImage from "hooks/useOptimizedImage";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: 50%;
    ${media.lg`
      padding-top: 32%;
    `}
  }
`;
const Title = styled.h1`
  position: absolute;
  top: 0;
`;
const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const MainImage = () => {
  const topImageUrl = useOptimizedImage("top");
  const history = useHistory();
  const onClick = () => {
    history.push("/courses/0");
  };

  return (
    <Container onClick={onClick}>
      <Title>Atsueigo Online - 論理的に英語を考える、英語学習法講座</Title>
      <Image src={topImageUrl} alt="main" />
    </Container>
  );
};

export default MainImage;
