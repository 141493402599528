import React, { useState } from "react";
import { Collapse, Button } from "@material-ui/core";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { ContentTitle } from "./components";

const Content = styled(Collapse)`
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    padding-top: 40px;
    width: 100%;
    background: ${({ theme, in: open }) =>
      open
        ? "none"
        : `linear-gradient(rgba(255,255,255,0), ${
            theme.palette.background.default + "cc"
          })`};
  }
`;

const Description = ({ skuId }: { skuId: string }) => {
  const description = useSelector((state) => {
    return state.skus[skuId].description;
  });
  const [open, setOpen] = useState(false);
  const onClick = () => {
    setOpen((prev) => !prev);
  };
  const buttonText = open ? "- 表示を減らす" : "+ もっと見る";
  return (
    <div style={{ position: "relative" }}>
      <ContentTitle>講義内容</ContentTitle>
      <Content collapsedHeight="104px" in={open}>
        {description.split("\n").map((item, key) => {
          return (
            <React.Fragment key={item + key}>
              {item}
              <br />
            </React.Fragment>
          );
        })}
      </Content>
      <Button onClick={onClick} color="secondary">
        {buttonText}
      </Button>
    </div>
  );
};

export default Description;
