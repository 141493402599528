import _ from "lodash";

import firebase, { db } from "./firebase";
import { OrdersState } from "redux/reducers/orders";
import { UserLectureState, UserLecture } from "redux/reducers/userLectures";
import { BooksState, Book } from "redux/reducers/books";

// export const getSkus = async (): Promise<SkusState> => {
//   try {
//     const result = (await db().collection("skus").get()).docs.map((doc) => ({
//       ...doc.data(),
//       id: doc.id,
//     }));

//     return _.keyBy(
//       result.map((res: any) => ({
//         title: res.title,
//         subTitle: res.subTitle,
//         description: res.description,
//         producer: res.producer,
//         price: res.price,
//         id: res.id,
//         type: res.type,
//         stripeSkuId: res.skuId,
//         summaries: [],
//         goodFors: [],
//         imageUrl: "",
//         coupons: {},
//       })),
//       "id"
//     );
//   } catch (error) {
//     return error;
//   }
// };

// export const getSku = async (skuId: string): Promise<Sku> => {
//   try {
//     const res = (await db().collection("skus").doc(skuId).get()).data();
//     if (!res) throw new Error("No sku");
//     return {
//       title: res.title,
//       subTitle: res.subTitle,
//       description: res.description,
//       producer: res.producer,
//       price: res.price,
//       id: res.id,
//       type: res.type,
//       stripeSkuId: res.skuId,
//       summaries: [],
//       goodFors: [],
//       imageUrl: "",
//       coupons: {},
//     };
//   } catch (error) {
//     return error;
//   }
// };

export const getBooks = async (): Promise<BooksState> => {
  try {
    const result = (await db().collection("books").get()).docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return _.keyBy(
      result.map((res: any) => ({
        id: res.id,
        title: res.title,
        description: res.description,
        detail: res.detail,
        producer: res.producer,
        price: res.price,
        type: res.type,
        stripeSkuId: res.skuId,
        imageUrl: res.imageUrl,
        subImages: res.subImages,
      })),
      "id"
    );
  } catch (error) {
    return error;
  }
};

export const getBook = async (bookId: string): Promise<Book> => {
  try {
    const res = (await db().collection("books").doc(bookId).get()).data();
    if (!res) throw new Error("No book");
    return {
      id: res.id,
      title: res.title,
      description: res.description,
      detail: res.detail,
      producer: res.producer,
      price: res.price,
      type: res.type,
      stripeSkuId: res.skuId,
      imageUrl: res.imageUrl,
      subImages: res.subImages,
    };
  } catch (error) {
    return error;
  }
};

export const postLecturesStart = async (lectureId: string, userId: string) => {
  // try {
  //   const userLectures = await getUserLectures(userId, lectureId);
  //   const timestamp = db.FieldValue.serverTimestamp();
  //   if (userLectures) {
  //     for (const userLecture of Object.values(userLectures)) {
  //       const data = userLecture.start
  //         ? { latestWatched: timestamp }
  //         : { start: timestamp, latestWatched: timestamp };
  //       await db().collection("userLectures").doc(userLecture.id).update(data);
  //     }
  //   } else {
  //     await db().collection("userLectures").add({
  //       userId: userId,
  //       lectureId,
  //       start: timestamp,
  //       latestWatched: timestamp,
  //     });
  //   }
  //   return;
  // } catch (error) {
  //
  //   return error;
  // }
};

export const putLecturesEnd = async (
  lectureId: string,
  userId: string
): Promise<UserLecture> => {
  try {
    // const userLectures = await getUserLectures(userId, lectureId);
    const end = db.FieldValue.serverTimestamp();

    // if (userLectures.length > 0) return;
    const result = await db()
      .collection("userLectures")
      .add({ userId, lectureId, end });
    const data = (await result.get()).data();

    return { id: result.id, lectureId, end: data?.end?.seconds };
  } catch (error) {
    return error;
  }
};

export const signupWithEmail = async (
  email: string,
  password: string
): Promise<firebase.auth.UserCredential> => {
  const result = await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password);
  return result;
};

export const signinWithEmail = async (email: string, password: string) => {
  const result = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  return result;
};

export const updateProfile = async (
  displayName: string,
  user: firebase.User
) => {
  try {
    const result = await user.updateProfile({ displayName });
    await db()
      .collection("users")
      .doc(user.uid)
      .set({ name: displayName }, { merge: true });
    return result;
  } catch (error) {
    return error;
  }
};

export const logout = async () => {
  try {
    const result = await firebase.auth().signOut();
    return result;
  } catch (error) {
    return error;
  }
};

export const getOrders = async (): Promise<OrdersState> => {
  const user = firebase.auth().currentUser;
  try {
    const result = (
      await db().collection("orders").where("userId", "==", user?.uid).get()
    ).docs.map((doc) => doc.data());

    if (result.length === 0) return {};

    return _.keyBy(
      result.map((res) => ({
        id: res.id,
        customerId: res.customerId,
        skus: _.keyBy(
          res.skus.map((sku: any) => ({
            amount: sku.amount_total,
            name: sku.description,
            id: sku.price.id,
            price: sku.price.unit_amount,
            productId: sku.price.product,
          })),
          "id"
        ),
      })),
      "id"
    );
  } catch (error) {
    return error;
  }
};

export const getUserLectures = async (
  userId: string,
  lectureId?: string
): Promise<UserLectureState> => {
  try {
    const result = lectureId
      ? (
          await db()
            .collection("userLectures")
            .where("userId", "==", userId)
            .where("lectureId", "==", lectureId)
            .get()
        ).docs?.map((doc) => ({ ...doc.data(), id: doc.id }))
      : (
          await db()
            .collection("userLectures")
            .where("userId", "==", userId)
            .get()
        ).docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
    return _.keyBy(
      result.map((res: any) => ({
        id: res.id,
        lectureId: res.lectureId,
        start: res.start?.seconds,
        end: res.end?.seconds,
        latestWatched: res.latestWatched?.seconds,
      })),
      "id"
    );
  } catch (error) {
    return error;
  }
};

export const getCurrentUser = async (): Promise<any> => {
  const user = firebase.auth().currentUser;
  try {
    const result = await db().collection("users").doc(user?.uid).get();
    return { ...result.data(), ...user, name: result.data()?.displayName || user?.displayName };
  } catch (error) {
    return error;
  }
};
