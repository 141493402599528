import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { useCourses } from "hooks/useCourses";
import MainImage from "./MainImage";
import Profile from "./Profile";
import CourseLineup from "./CourseLineup";
import Value from "./Value";
import OrderedSkus from "./OrderedSkus";

const Container = styled.main``;
const Landing: React.FC<RouteComponentProps> = () => {
  const courses = useCourses();

  return (
    <Container>
      <MainImage />
      <OrderedSkus />
      <CourseLineup courses={courses} />
      <Value />
      <Profile />
    </Container>
  );
};

export default Landing;
