import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Container = styled.div`
  padding: ${({ theme: { spacing } }) => spacing(2)}px;
`;
const Description = ({ visible = false }) => {
  const { skuId } = useParams();
  const description = useSelector(
    (state) => skuId && state.skus[skuId].description
  );
  if (!visible) return null;

  return (
    <Container>
      <Typography variant="h6">このコースについて</Typography>
      <Typography>
        {description?.split("\n").map((item, key) => {
          return (
            <React.Fragment key={item + key}>
              {item}
              <br />
            </React.Fragment>
          );
        })}
      </Typography>
    </Container>
  );
};

export default Description;
