import { reducerWithInitialState } from "typescript-fsa-reducers";
import accountActions from "../actions/account";
import userLecturesActions from "../actions/userLectures";

export type UserLecture = {
  id: string;
  lectureId: string;
  end: number;
};

export type UserLectureState = {
  [key: string]: UserLecture;
};

const initialState = {} as UserLectureState;

const userLecturesReducer = reducerWithInitialState(initialState)
  .case(accountActions.getUserLecturesAction.done, (state, payload) => {
    return payload.result;
  })
  .case(userLecturesActions.putLecturesEndAction.done, (state, payload) => {
    return { ...state, [payload.result.id]: payload.result };
  })
  .case(accountActions.logoutAction.done, () => {
    return initialState;
  })
  .default((state) => state);

export default userLecturesReducer;
