import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import { getOrders as getOrdersApi } from "utils/api";
import { OrdersState } from "../reducers/orders";

const actionCreator = actionCreatorFactory();

const getOrdersAction = actionCreator.async<
  {},
  { orders: OrdersState },
  { code: number; description: string }
>("GET_ORDERS_ACTION");

export const getOrders = () => async (dispatch: Dispatch<Action>) => {
  dispatch(getOrdersAction.started({}));

  try {
    const orders = await getOrdersApi();

    dispatch(
      getOrdersAction.done({
        params: {},
        result: { orders },
      })
    );

    return orders;
  } catch (error) {
    dispatch(
      getOrdersAction.failed({
        params: {},
        error: { code: error.code, description: error.description },
      })
    );

    throw error;
  }
};

const ordersActions = {
  getOrdersAction,
};

export default ordersActions;
