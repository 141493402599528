export const stripePkKey = process.env.REACT_APP_STRIPE_PK_KEY ?? "pk_live_I3m2zH3ZYAEDK2oUMGWP5x2N00NBM9eIYJ"
export const course0ProductId = process.env.REACT_APP_COURSE0_PRODUCT_ID ?? "prod_HPS4GNhXrgBzU7"
export const course0PriceId = process.env.REACT_APP_COURSE0_PRICE_ID ?? "price_1GqdAkBs2HZZN3Bqdmdv2m97"

export const course0Coupon0PriceId = process.env.REACT_APP_COURSE0_COUPON0_PRICE_ID ?? "price_1HAwzCBs2HZZN3BqMqfMvx5a"
export const course0Coupon1PriceId = process.env.REACT_APP_COURSE0_COUPON1_PRICE_ID ?? "price_1HFbcjBs2HZZN3BqFfz009XP"
export const course0Coupon2PriceId = process.env.REACT_APP_COURSE0_COUPON2_PRICE_ID ?? "price_1HLPaFBs2HZZN3BqsPHkDuiD"

export const distinction1PriceId = process.env.REACT_APP_DISTINCTION1_PRICE_ID ?? "price_1GqdC5Bs2HZZN3Bqy5wS2v7S"
export const distinction2PriceId = process.env.REACT_APP_DISTINCTION2_PRICE_ID ?? "price_1GqdCFBs2HZZN3BqYxDnxHOw"
export const vocablaristPriceId = process.env.REACT_APP_VOCABLARIST_PRICE_ID ?? "price_1GqdAtBs2HZZN3BqCEWaQj8f"
export const deliveryFeePriceId = process.env.REACT_APP_DELIVERY_FEE_PRICE_ID ?? "price_1GqdCJBs2HZZN3Bqje7bn9nM"
