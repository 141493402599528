import React from "react";
import styled from "styled-components";
import { Typography, Grid as MuiGrid } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { CheckCircle, PlayCircleOutline } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { darken } from "polished";

const Grid = styled(MuiGrid)`
  > * {
    margin-right: ${({ theme: { spacing } }) => spacing(1)}px;
  }
`;

const StyledLink = styled(Link)<{ watching: number; disabled?: number }>`
  padding: ${({ theme: { spacing } }) => spacing(1)}px
    ${({ theme: { spacing } }) => spacing(2)}px;
  background-color: ${({ watching }) => (watching ? "#e6f2f5" : "#ffffff")};
  cursor: pointer;
  :hover {
    background-color: ${({ watching }) =>
      watching ? darken(0.1, "#e6f2f5") : darken(0.1, "#ffffff")};
  }

  pointer-events: ${({ disabled = false }) => (disabled ? "none" : "auto")};
`;

const LectureItem = ({ lectureId }: { lectureId: string }) => {
  const { skuId, lectureId: currentLectureId } = useParams();
  const isCourseDetail = useRouteMatch("/courses/:skuId")?.isExact;
  const watched = useSelector((state) => {
    if (isCourseDetail) return false;
    return Boolean(
      Object.values(state.userLectures).find((ul) => ul.lectureId === lectureId)
        ?.end
    );
  });
  const lecture = useSelector((state) => state.lectures[lectureId]);
  const minutes = Math.floor(lecture.amountSeconds / 60);
  const watching = currentLectureId === lectureId ? 1 : 0;

  return (
    <StyledLink
      to={`/courses/${skuId}/lectures/${lectureId}`}
      watching={watching}
      disabled={isCourseDetail ? 1 : 0}
    >
      <Grid container alignItems="flex-start">
        {watched ? (
          <CheckCircle style={{ color: green[600] }} />
        ) : (
          <PlayCircleOutline color="disabled" />
        )}
        <div>
          <Typography>
            {lecture.index + 1}. {lecture.title}
          </Typography>
          <Typography>{minutes}分</Typography>
        </div>
      </Grid>
    </StyledLink>
  );
};

export default LectureItem;
