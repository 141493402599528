import { reducerWithInitialState } from "typescript-fsa-reducers";
import ordersActions from "../actions/orders";
import accountActions from "../actions/account";

type Sku = {
  amount: number;
  name: string;
  id: string;
  price: number;
  productId: string;
};

type Skus = {
  [key: string]: Sku;
};

type Order = {
  id: string;
  skus: Skus;
  customerId: string;
};

export type OrdersState = {
  [key: string]: Order;
};

const initialState: OrdersState = {};

const ordersReducer = reducerWithInitialState(initialState)
  .case(ordersActions.getOrdersAction.done, (_, payload) => {
    return { ...payload.result.orders };
  })
  .case(accountActions.logoutAction.done, () => {
    return initialState;
  })
  .default((state) => state);

export default ordersReducer;
