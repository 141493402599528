import React from "react";
import styled from "styled-components";
import { Menu, MenuItem, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "redux/actions/account";
import { useHistory } from "react-router-dom";
import { KeyboardArrowRight } from "@material-ui/icons";
import { useOrderedSkus } from "hooks/useOrders";

const OrderedSkuTitle = styled.div`
  font-weight: bold;
`;

type Props = {
  anchorEl: Element | null;
  setAnchorEl: (next: Element | null) => void;
};

const AccountMenu: React.FC<Props> = ({ anchorEl = null, setAnchorEl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const orderedSkus = useOrderedSkus();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = () => {
    setAnchorEl(null);
    dispatch(logout());
  };

  const onClickCourse = (skuId: string) => () => {
    history.replace(`/courses/${skuId}/lectures`);
  };

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem button={false}>
          <OrderedSkuTitle>購入済みコース</OrderedSkuTitle>
        </MenuItem>
        {orderedSkus.map((sku) => {
          if (!sku) return null;
          return (
            <MenuItem key={sku.id} onClick={onClickCourse(sku.id)}>
              {sku.title} <KeyboardArrowRight />
            </MenuItem>
          );
        })}
        <Divider variant="middle" />
        <MenuItem onClick={onClickLogout}>ログアウト</MenuItem>
      </Menu>
    </div>
  );
};

export default AccountMenu;
