import { combineReducers } from "redux";
import modals, { ModalState } from "./modals";
import account, { AccountState } from "./account";
import skus, { SkusState } from "./skus";
import lectures, { LecturesState } from "./lectures";
import books, { BooksState } from "./books";
import orders, { OrdersState } from "./orders";
import sections, { SectionsState } from "./sections";
import userLectures, { UserLectureState } from "./userLectures";
import coupon, { CouponState } from "./coupon";

import "react-redux";

export type AppState = {
  modals: ModalState;
  account: AccountState;
  skus: SkusState;
  lectures: LecturesState;
  books: BooksState,
  orders: OrdersState;
  sections: SectionsState;
  userLectures: UserLectureState;
  coupon: CouponState;
};

declare module "react-redux" {
  interface DefaultRootState extends AppState { }
}

const reducer = combineReducers<AppState>({
  modals,
  account,
  skus,
  books,
  lectures,
  orders,
  sections,
  userLectures,
  coupon,
});

export default reducer;
