import React, { useEffect, useState } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { useCallback } from "react";
import { StylesProvider } from "@material-ui/styles";
import styled, { ThemeProvider } from "styled-components";
import { logout } from "./redux/actions/account";

import theme from "./theme";
import Routes from "./routes";
import {
  getCurrentUser,
  getUserLectures,
} from "./redux/actions/account";
import firebase from "./utils/firebase";
import { useDispatch } from "react-redux";
import { getOrders } from "./redux/actions/orders";

const CircleProgressContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

function App() {
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);

  const setupUser = useCallback(
    async (userAuth: firebase.User | null) => {
      if (!userAuth) {
        dispatch(logout())
        return;
      }

      await dispatch(getCurrentUser());
      await dispatch(getOrders());
      await dispatch(getUserLectures());
      return;
    },
    [dispatch]
  );

  useEffect(() => {
    const unregister = firebase.auth().onAuthStateChanged(async (user) => {
      console.log('onAuthchange', user)
      await setupUser(user);
      setInitialLoading(false);
    });

    return () => {
      unregister();
    };
  }, [setupUser]);

  if (initialLoading) {
    return (
      <CircleProgressContainer>
        <CircularProgress />
      </CircleProgressContainer>
    );
  }

  return (
    <React.Fragment>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
