import { reducerWithInitialState } from "typescript-fsa-reducers";

type Section = {
  skuId: string;
  index: number;
  title: string;
  id: string;
};

export type SectionsState = {
  [key: string]: Section;
};

const initialState: SectionsState = {
  "0": { skuId: "0", index: 0, title: "英語学習の全体像を捉える", id: "0" },
  "1": { skuId: "0", index: 1, title: "英語の基礎学習をマスターする", id: "1" },
  "2": { skuId: "0", index: 2, title: "英語の応用学習をマスターする（インプット編）", id: "2" },
  "3": { skuId: "0", index: 3, title: "英語の応用学習をマスターする（アウトプット編）", id: "3" },
};

const sectionsReducer = reducerWithInitialState(initialState).default(state => state);

export default sectionsReducer;
