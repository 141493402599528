import React from "react";
import styled from "styled-components";
import ReactStars from "react-stars";

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 1rem; */
  > * {
    margin-right: 8px;
  }
`;

const RatingNumber = styled.span<{ sizeRatio: number }>`
  font-size: ${({ theme: { typography }, sizeRatio }) =>
    typography.pxToRem(14 * sizeRatio)};
  color: #ffaa0e;
`;

const RatingCount = styled.span<{ sizeRatio: number }>`
  font-size: ${({ theme: { typography }, sizeRatio }) =>
    typography.pxToRem(12 * sizeRatio)};
  color: #999;
`;

const Explanation = styled.p<{ sizeRatio: number }>`
  font-size: ${({ theme: { typography }, sizeRatio }) =>
    typography.pxToRem(10 * sizeRatio)};
  color: #999;
`

type Props = {
  value: number;
  count: number;
  sizeRatio?: number;
};

export const Rating = ({ value, count, sizeRatio = 1 }: Props) => {
  const ratingValue = Math.round(value / 0.5) * 0.5;
  return (
    <div>
      <RatingContainer>
        <RatingNumber sizeRatio={sizeRatio}>{value}</RatingNumber>
        <ReactStars value={ratingValue} half edit={false} size={15 * sizeRatio} />
        <RatingCount sizeRatio={sizeRatio}>({count}件の評価)</RatingCount>
      </RatingContainer>
      <Explanation sizeRatio={sizeRatio}>評価はモニターのアンケート結果を反映したものです。</Explanation>
    </div>
  );
};
