import ReactPlayer from "react-player";
import styled from "styled-components";
import { Tab as MuiTab, Tabs as MuiTabs } from "@material-ui/core";
import media from "utils/media";

export const VideoContainer = styled.div`
  position: relative;
  padding-top: 40.25%;
  background-color: #15171c;

  ${media.sp`
    padding-top: 61.25%;
  `}
`;

export const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const TabsContainer = styled.div`
  width: 50%;
  margin: auto;

  ${media.sp`
    width: 100%;
  `}
`;

export const TabContentContainer = styled.div`
  padding: 0 96px;

  ${media.sp`
    padding: 0;
  `}
`;

export const Tabs = styled(MuiTabs)`
  border-bottom: solid 1px #cccccc;
`;

export const Tab = styled(MuiTab)`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
`;
