import React from "react"
import Helmet from "react-helmet"

const TITLE = "Atsueigo ONLINE - 論理的に英語を考える、英語学習法講座"
const DESCRIPTION = "【Atsueigo公式】論理的に英語を考える、英語学習法講座 'Atsueigo ONLINE'。効率的に英語学習を進めるには論理に基づいたムダのない学習アプローチが不可欠です。最短距離で英語学習を進めるための土台を、一緒に築き上げましょう！"
const Meta = () => {
  return (
    <Helmet>
      <title>{TITLE}</title>
      <meta name="description" content={DESCRIPTION} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={TITLE} />
      <meta property="og:description" content={DESCRIPTION} />
      <meta property="og:site_name" content={TITLE} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="/atsueigo_online.jpg" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="twitter:image" content="/atsueigo_online.jpg" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-precomposed.png" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-57x57.png" sizes="57x57" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-60x60.png" sizes="60x60" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-72x72.png" sizes="72x72" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-76x76.png" sizes="76x76" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-114x114.png" sizes="114x114" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-120x120.png" sizes="120x120" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-144x144.png" sizes="144x144" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-152x152.png" sizes="152x152" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon-180x180.png" sizes="180x180" />
      <link rel="icon" href="/icons/android-chrome-36x36.png" sizes="36x36" />
      <link rel="icon" href="/icons/android-chrome-48x48.png" sizes="48x48" />
      <link rel="icon" href="/icons/android-chrome-72x72.png" sizes="72x72" />
      <link rel="icon" href="/icons/android-chrome-96x96.png" sizes="96x96" />
      <link rel="icon" href="/icons/android-chrome-144x144.png" sizes="144x144" />
      <link rel="icon" href="/icons/android-chrome-192x192.png" sizes="192x192" />
    </Helmet>
  )
}
export default Meta
