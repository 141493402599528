import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

type CouponActionProps = string | null

const couponActions = {
  setCoupon: actionCreator<CouponActionProps>('SET_COUPON'),
  clearCoupon: actionCreator('CLEAR_COUPON')
};

export default couponActions;
