import { reducerWithInitialState } from "typescript-fsa-reducers";
import couponActions from "../actions/coupon";

export type CouponState = string | null;

const initialState = "" as CouponState;

const modalsReducer = reducerWithInitialState(initialState)
  .case(couponActions.setCoupon, (_, code) => {
    return code;
  })
  .case(couponActions.clearCoupon, () => null)
  .default((state) => state);

export default modalsReducer;
