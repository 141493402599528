import React from "react";
import styled from "styled-components";
import { Check } from "@material-ui/icons";
import media from "utils/media";
import { ContentTitle } from "./components";
import { useSelector } from "react-redux";

const ListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  width: calc((100% - 48px) / 2);
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;

  > * {
    margin-right: 8px;
  }

  ${media.sp`
    flex-basis: 100%;
  `}
`;

const Summary = ({ skuId }: { skuId: string }) => {
  const summaries = useSelector((state) => {
    return state.skus[skuId].summaries;
  });
  return (
    <div>
      <ContentTitle>学習内容</ContentTitle>
      <ListContainer>
        {summaries.map((item, index) => (
          <ListItem key={index}>
            <Check color="secondary" />
            {item}
          </ListItem>
        ))}
      </ListContainer>
    </div>
  );
};

export default Summary;
