import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Button, Typography } from "@material-ui/core";
//@ts-ignore
import { Twitter, Facebook } from "react-social-sharing";

const Container = styled.div``;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextArea = styled.textarea`
  resize: none;
  flex: 1;
  margin-right: 8px;
  font-size: ${({ theme: { typography } }) =>
    typography.pxToRem(16)};
`;

export const SocialShare = ({ link }: { link: string }) => {
  const textArea: any = useRef(null);
  const [successText, setSuccessText] = useState('')

  const onClick = (e: any) => {
    if (!textArea && !textArea.current) return;
    textArea.current.select();
    document.execCommand("copy");
    e.target.focus();
    setSuccessText("リンクをコピーしました！")
  };
  return (
    <Container>
      {successText && <Typography color="primary" >{successText}</Typography>}
      <ContentContainer>
        <TextArea ref={textArea} defaultValue={link} readOnly />
        <Button variant="contained" color="secondary" onClick={onClick}>
          コピー
        </Button>
      </ContentContainer>
      <ContentContainer>
        <Twitter link={link} />
        <Facebook link={link} />
      </ContentContainer>
    </Container>
  );
};
