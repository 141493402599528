import React, { useState } from "react";
import styled from "styled-components";
import { Tabs, Tab } from "@material-ui/core";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import { setAccount } from "redux/actions/account";
import useModal from "hooks/useModal";
import firebase from "utils/firebase";
import { FAQ_URL } from "constants/index";

const Container = styled.div`
  > * {
    margin-bottom: ${({ theme: { spacing } }) => spacing(2)}px;
  }
`;

const Recommend = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
`;

const FaqLink = styled.a`
  color: blue;
  text-decoration: underline;
  font-size: 1.2rem;
`;

const uiConfig = (path: string, cb: (props: any) => boolean) => ({
  signInFlow: "popup",
  signInSuccessUrl: path,
  signInOptions: [
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: cb,
  },
});

const LoginCard = ({ type = "signin" }) => {
  // 0 -> Signin
  // 1 -> Signup
  const [tabValue, setTabValue] = useState(type === "signin" ? 0 : 1);
  const [, setShowModal] = useModal("login");
  const location = useLocation();

  const dispatch = useDispatch();
  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const loginCallBack = (props: any) => {
    if (props?.user?.uid) {
      dispatch(
        setAccount({ id: props.user.uid, name: props.user.displayName })
      );
    }
    setShowModal(false);
    return true;
  };

  return (
    <Container>
      <Tabs
        value={tabValue}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {/* Don't change the order signin and signup!! */}
        <Tab label="ログイン" />
        <Tab label="新規登録" />
      </Tabs>
      <Recommend>
        パスワードの入力ミスなどのトラブル防止のため、SNSログインがおすすめです！
      </Recommend>
      <StyledFirebaseAuth
        uiConfig={uiConfig(location.pathname + location.search, loginCallBack)}
        firebaseAuth={firebase.auth()}
      />
      <FaqLink target="_blank" rel="noopener noreferrer" href={FAQ_URL}>
        お困りですか？
      </FaqLink>
    </Container>
  );
};

export default LoginCard;
