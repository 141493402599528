import { useSelector } from "react-redux";
import { Sku } from "redux/reducers/skus";
import _ from "lodash";

export const useOrdersByCourse = (skuId?: string) => {
  return useSelector((state) => {
    const orders = state.orders;

    if (!skuId || !Boolean(orders)) return [];

    const sku = state.skus[skuId];

    return Object.values(orders).filter((order) =>
      Object.values(order.skus)
        .map((sku) => sku.productId)
        .includes(sku.productId)
    );
  });
};

export const useOrdersByLecture = (lectureId: string) => {
  const skuId = useSelector((state) => {
    const lecture = state.lectures[lectureId];
    return state.sections?.[lecture?.sectionId]?.skuId;
  });

  return useOrdersByCourse(skuId);
};

export const useOrderedSkus = () =>
  useSelector((state) => {
    const orders = Object.values(state.orders);
    const productIds = _.flatMap(
      orders.map((order) => Object.values(order.skus))
    ).map((sku) => sku.productId);
    const skus = productIds.map((productId) =>
      Object.values(state.skus).find((sku) => sku.productId === productId)
    );
    const filteredSkus = skus.filter(
      (sku): sku is Required<Sku> => sku !== undefined
    );

    return filteredSkus;
  });
