import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Button as MuiButton,
} from "@material-ui/core";
import { Menu as MenuIcon, KeyboardArrowDown } from "@material-ui/icons";
import { blue, grey } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import useModal from "hooks/useModal";
import Button from "./Button";
import logo from "assets/images/logo.svg";
import AccountMenu from "./AccountMenu";

const theme = {
  color: "#333333",
  background: "#FFFFFF",
  search: {
    color: grey[800],
  },
  indicator: {
    background: blue[600],
  },
};

const AppBar = styled(MuiAppBar)`
  background: ${theme.background};
  color: ${theme.color};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
`;

const Logo = styled(Link)`
  display: flex;
  justify-self: center;
  img {
    height: 28px;
  }
`;

const IconButton: any = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const AccountButton = styled(MuiButton)`
  font-size: 1.4rem;
  font-weight: bold;
`;

const Account: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };
  return (
    <>
      <AccountButton onClick={onClick}>{children}</AccountButton>
      <AccountMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

type Props = {
  onDrawerToggle?: () => void;
};

const Header: React.FC<Props> = ({ onDrawerToggle }) => {
  const [, setShowModal] = useModal("login");
  const onClickAccountButton = (type: string) => () => {
    setShowModal(true, { type });
  };
  const isLoggedIn = useSelector((state) => Boolean(state.account));
  const name = useSelector((state) => state?.account?.name);
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" justify="center">
            <Grid item style={{ marginRight: "auto" }}>
              <Logo to="/">
                <img src={logo} alt="Atsueigo" />
              </Logo>
            </Grid>
            <Hidden smDown>
              {isLoggedIn ? (
                <Account>
                  {name && name.length > 20 ? "Account" : name}
                  <KeyboardArrowDown />
                </Account>
              ) : (
                <Grid item style={{ marginLeft: "auto" }}>
                  <Grid container alignItems="center" justify="center">
                    <Grid item style={{ marginRight: "16px" }}>
                      <Button
                        clickHandler={onClickAccountButton("signin")}
                        emptyColor={true}
                      >
                        ログイン
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button clickHandler={onClickAccountButton("signup")}>
                        新規登録
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Hidden>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
