import firebase from "./firebase";
import { stripePkKey, deliveryFeePriceId } from "./config";

const checkout = (skuId: string, stripeSkuId: string, quantity: number, userId: string, type: string) => {
  // @ts-ignore
  const stripe = window.Stripe(stripePkKey);
  const currentUser = firebase.auth().currentUser;
  const isBook = type === "book"
  //TODO: Change the URLs to production one
  const callbackPath = isBook ? `/books/${skuId}/thx` : `/courses/${skuId}/lectures`
  const info: any = {
    lineItems: [{ price: stripeSkuId, quantity: quantity || 1 }],
    mode: 'payment',
    successUrl: `${window.location.origin}/callback/checkout?path=${callbackPath}&skuId=${skuId}`,
    cancelUrl:  window.location.href,
    clientReferenceId: userId,
  };

  if (currentUser?.email) {
    info.customerEmail = currentUser.email
  }

  if (isBook) {
    info.shippingAddressCollection = { allowedCountries: ["JP"] };
    info.lineItems = info.lineItems.concat({ price: deliveryFeePriceId, quantity: 1 })
  }

  stripe.redirectToCheckout(info)

  return;
};

export default checkout;
