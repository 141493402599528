import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useCourses } from "hooks/useCourses";
import media from "utils/media";
import {
  TRANSACTION_LAW_URL,
  PRIVACY_POLICY_URL,
  TOS_URL,
  FAQ_URL,
} from "constants/index";

const Container = styled.footer`
  display: flex;
  justify-content: center;
  background-color: rgb(242, 243, 245);
`;
const Inner = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 48px 16px;
`;
const Navigation = styled.nav`
  margin-bottom: 80px;
`;
const NavList = styled.ul`
  display: flex;
  ${media.sp`
    flex-wrap: wrap;
  `}
`;
const Section = styled.section`
  width: calc((100% - 120px) / 4);
  &:not(:last-child) {
    margin-right: 40px;
  }
  ${media.sp`
    width: calc((100% - 40px) / 2);
    &:not(:last-child) {
      margin-right: 0;
    }
    &:nth-child(2n + 1) {
      margin-right: 40px;
    }
    &:nth-child(2n + 3), &:nth-child(2n + 4) {
      margin-top: 40px;
    }
  `}
  ${media.xs`
    width: 100%;
    &:nth-child(2n + 1) {
      margin-right: 0;
    }
    &:nth-child(2n + 3), &:nth-child(2n + 4) {
      margin-top: 0;
    }
    &:not(:first-child) {
      margin-top: 40px;
    }
  `}
`;
const Heading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
`;
const LinkList = styled.ul``;
const LinkItem = styled.li`
  line-height: 1.3;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  a {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #666;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
`;
const AllRightsReserved = styled.div`
  font-size: 1.2rem;
  color: #999;
  padding: 24px 0;
  text-align: center;
`;
const Footer = () => {
  const courses = useCourses();
  return (
    <Container>
      <Inner>
        <Navigation>
          <NavList>
            <Section>
              <Heading>人気のコース</Heading>
              <LinkList>
                {Object.entries(courses).map(([key, course]) => (
                  <LinkItem key={key}>
                    <Link to={`/courses/${key}`}>{course.title}</Link>
                  </LinkItem>
                ))}
              </LinkList>
            </Section>
            <Section>
              <Heading>外部リンク</Heading>
              <LinkList>
                <LinkItem>
                  <a
                    href="https://www.youtube.com/user/fzsdjbncf2000/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube - Atsueigo
                  </a>
                </LinkItem>
                <LinkItem>
                  <a
                    href="https://twitter.com/atsueigo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter - ATSU
                  </a>
                </LinkItem>
                <LinkItem>
                  <a
                    href="http://www.atsueigo.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog - Atsueigo.com｜英語でグローバルを切り拓く
                  </a>
                </LinkItem>
                <LinkItem>
                  <a
                    href="https://www.facebook.com/pages/ATSU/1554748681461913"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook - Atsueigo
                  </a>
                </LinkItem>
              </LinkList>
            </Section>
            <Section>
              <Heading>その他</Heading>
              <LinkList>
                <LinkItem>
                  <a href={TOS_URL}>利用規約</a>
                </LinkItem>
                <LinkItem>
                  <a href={TRANSACTION_LAW_URL}>特定商取引法に基づく表記</a>
                </LinkItem>
                <LinkItem>
                  <a href={PRIVACY_POLICY_URL}>プライバシーポリシー</a>
                </LinkItem>
                <LinkItem>
                  <a target="_blank" rel="noopener noreferrer" href={FAQ_URL}>
                    お問い合わせ・よくある質問
                  </a>
                </LinkItem>
              </LinkList>
            </Section>
          </NavList>
        </Navigation>
        <AllRightsReserved>© Atsueigo All Rights Reserved.</AllRightsReserved>
      </Inner>
    </Container>
  );
};

export default Footer;
