import actionCreatorFactory from "typescript-fsa";
import { Action, Dispatch } from "redux";
import {
  signupWithEmail as signupWithEmailApi,
  updateProfile,
  signinWithEmail as signinWithEmailApi,
  logout as logoutApi,
  getUserLectures as getUserLecturesApi,
  getCurrentUser as getCurrentUserApi,
} from "utils/api";
import { UserLectureState } from "../reducers/userLectures";

const actionCreator = actionCreatorFactory();

const signupWithEmailAction = actionCreator.async<
  { email: string; password: string; name: string },
  { id: string, email: string, name: string },
  { code: number; description: string }
>("SIGNUP");

const signinWithEmailAction = actionCreator.async<
  { email: string; password: string },
  { id: string },
  { code: number; description: string }
>("SIGNIN");

const logoutAction = actionCreator.async<
  {},
  {},
  { code: number; description: string }
>("LOGOUT");

export const signup = (email: string, password: string, name: string) => async (
  dispatch: Dispatch<Action>
) => {
  dispatch(signupWithEmailAction.started({ email, password, name }));

  try {
    const result: any = await signupWithEmailApi(email, password);

    result.user && await updateProfile(name, result.user);
    dispatch(
      signupWithEmailAction.done({
        params: { email, password, name },
        result: { id: result.user.uid, name, email },
      })
    );

    return result;
  } catch (error) {
    dispatch(
      signupWithEmailAction.failed({
        params: { email, password, name },
        error: { code: error.code, description: error.description },
      })
    );

    throw error;
  }
};

export const signin = (email: string, password: string) => async (
  dispatch: Dispatch<Action>
) => {
  dispatch(signinWithEmailAction.started({ email, password }));

  try {
    const result = await signinWithEmailApi(email, password);
    if (result.user?.uid === undefined) throw new Error("uid is null")
    dispatch(
      signinWithEmailAction.done({
        params: { email, password },
        result: { id: result.user?.uid },
      })
    );

    return result;
  } catch (error) {
    dispatch(
      signinWithEmailAction.failed({
        params: { email, password },
        error: { code: error.code, description: error.description },
      })
    );

    throw error;
  }
};

export const logout = () => async (dispatch: Dispatch<Action>) => {
  dispatch(logoutAction.started({}));

  try {
    const result = await logoutApi();
    dispatch(
      logoutAction.done({
        params: {},
        result: {},
      })
    );

    return result;
  } catch (error) {
    dispatch(
      logoutAction.failed({
        params: {},
        error: { code: error.code, description: error.description },
      })
    );

    return error;
  }
};

type SetAccountPayloadProps = {
  id: string;
  name: string;
};
export const setAccount = actionCreator<SetAccountPayloadProps | null>(
  "SET_ACCOUNT"
);

type SetUserLecturesPayloadProps = {
  lectureId: string;
  start: number;
  end: number;
};
export const setUserLectures = actionCreator<SetUserLecturesPayloadProps>(
  "SET_USER_LECTURES"
);

type SetLectureStartPayloadProps = {
  lectureId: string;
  start: number;
};
export const setLectureStart = actionCreator<SetLectureStartPayloadProps>(
  "SET_LECTURE_START"
);

type SetLectureEndPayloadProps = {
  lectureId: string;
  end: number;
};
export const setLectureEnd = actionCreator<SetLectureEndPayloadProps>(
  "SET_LECTURE_END"
);

export const getUserLecturesAction = actionCreator.async<
  {},
  UserLectureState,
  { code: number; description: string }
>("GET_USER_LECTURES");
export const getUserLectures = (lectureId?: string) => async (dispatch: Dispatch<Action>, getState: any) => {

  const userId = getState().account?.id

  dispatch(getUserLecturesAction.started({}));

  try {
    const result = await getUserLecturesApi(userId, lectureId);
    dispatch(
      getUserLecturesAction.done({
        params: {},
        result,
      })
    );

    return result;
  } catch (error) {
    dispatch(
      getUserLecturesAction.failed({
        params: {},
        error: { code: error.code, description: error.description },
      })
    );

    return error;
  }
};

export const getCurrentUserAction = actionCreator.async<
  {},
  { name: string, id: string },
  { code: number; description: string }
>("GET_CURRENT_USER");
export const getCurrentUser = () => async (dispatch: Dispatch<Action>) => {
  dispatch(getCurrentUserAction.started({}));

  try {
    const result = await getCurrentUserApi();
    
    dispatch(
      getCurrentUserAction.done({
        params: {},
        result: {
          name: result.name,
          id: result.uid
        },
      })
    );

    return result;
  } catch (error) {
    dispatch(
      getCurrentUserAction.failed({
        params: {},
        error: { code: error.code, description: error.description },
      })
    );

    return error;
  }
};

const accountActions = {
  setAccount,
  signupWithEmailAction,
  signin,
  logoutAction,
  setUserLectures,
  setLectureStart,
  setLectureEnd,
  getUserLecturesAction,
  getCurrentUserAction,
};

export default accountActions;
