import React from "react";

import LectureItem from "./LectureItem";
import useLectures from "hooks/useLectures";

const useLectureIds = (sectionId: string): string[] =>
  useLectures(sectionId, (lectures) => lectures.map((lec) => lec.id));

const Lectures: React.FC<{ sectionId: string }> = ({ sectionId }) => {
  const lectureIds = useLectureIds(sectionId);

  return (
    <>
      {lectureIds.map((id) => (
        <LectureItem lectureId={id} key={id} />
      ))}
    </>
  );
};

export default Lectures;
