import React, { useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { sendPurchaseEvent } from "utils/event_tracker";
import { useDispatch } from "react-redux";
import couponActions from "redux/actions/coupon";

const CheckoutCallback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const path = params.get("path");
  const skuId = params.get("skuId");

  useEffect(() => {
    dispatch(couponActions.clearCoupon());
  }, [dispatch]);

  sendPurchaseEvent(skuId);

  return <Redirect to={path} />;
};

export default CheckoutCallback;
