import React from "react";
import styled from "styled-components";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useParams, useRouteMatch } from "react-router-dom";

import Lectures from "./Lectures";
import useLectures from "hooks/useLectures";

const Heading = styled(Typography)`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  font-weight: bold;
`;

const Summary = styled(ExpansionPanelSummary)`
  background-color: #f2f3f5;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Details = styled(ExpansionPanelDetails)`
  padding: 0;
  /* > * {
    margin-bottom: ${({ theme: { spacing } }) => spacing(1)}px;
  } */
`;

const useLectureInfo = (
  sectionId: string
): { lectureIds: string[]; amountMinutes: number } =>
  useLectures(sectionId, (lectures) => {
    const lectureIds = lectures.map((lec) => lec.id);
    const amountMinutes = Math.floor(
      _.sum(lectures.map((lec) => lec.amountSeconds)) / 60
    );

    return { lectureIds, amountMinutes };
  });

const Section: React.FC<{ sectionId: string }> = ({ sectionId }) => {
  const section = useSelector((state) => state.sections[sectionId]);
  const { lectureIds, amountMinutes } = useLectureInfo(sectionId);
  const { lectureId: currentLectureId } = useParams();
  const isCourseDetail = useRouteMatch("/courses/:skuId")?.isExact;

  const doneLecturesCount = useSelector((state) => {
    const userLectures = Object.values(state.userLectures)
      .filter((ul) => lectureIds.includes(ul.lectureId))
      .filter((ul) => Boolean(ul.end));
    return userLectures.length;
  });
  const description = `${doneLecturesCount} / ${lectureIds.length} | ${amountMinutes}分`;
  const defaultExpanded = isCourseDetail
    ? true
    : currentLectureId
    ? lectureIds.includes(currentLectureId)
    : false;

  if (!section || !lectureIds) return null;

  return (
    <ExpansionPanel defaultExpanded={defaultExpanded}>
      <Summary expandIcon={<ExpandMore />}>
        <SummaryContainer>
          <Heading>{section?.title}</Heading>
          <Typography>{description}</Typography>
        </SummaryContainer>
      </Summary>
      <Details style={{ flexDirection: "column" }}>
        <Lectures sectionId={sectionId} />
      </Details>
    </ExpansionPanel>
  );
};

export default Section;
