import React from "react";
import styled from "styled-components";
import Container from "templates/Container";
import atsu from "assets/images/atsu.png";
import media from "utils/media";
import youtube from "assets/images/youtube.png";

const Inner = styled.div`
  display: flex;
  ${media.xs`
    flex-wrap: wrap;
    justify-content: center;
  `}
`;
const Image = styled.img`
  height: 240px;
  flex-basis: 240px;
  width: 240px;
  min-width: 240px;
  border-radius: 4px;
  ${media.sm`
    height: 120px;
    flex-basis: 120px;
    width: 120px;
    min-width: 120px;
  `}
  ${media.xs`
    height: auto;
    flex-basis: 160px;
    width: 160px;
    min-width: 160px;
    margin-bottom: 16px;
  `}
`;
const Content = styled.div`
  flex-grow: 1;
  margin-left: 40px;
  ${media.sm`
    margin-left: 24px;
  `}
  ${media.xs`
    margin-left: 0;
  `}
`;
const Name = styled.h2`
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 8px;
  ${media.sm`
    font-size: 1.8rem;
  `}
  ${media.xs`
    text-align: center;
  `}
`;
const YouTube = styled.a`
  margin-left: 16px;
  &:hover {
    opacity: 0.8;
  }
  img {
    height: 16px;
  }
`;
const TagList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;
const Tag = styled.span`
  font-size: 1rem;
  background-color: #ddd;
  padding: 0 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;
const Description = styled.p`
  font-size: 1.4rem;
  ${media.sm`
    font-size: 1.2rem;
  `}
  ${media.xs`
    font-size: 1.1rem;
  `}
`;
const Profile = () => (
  <Container>
    <Inner>
      <div>
        <Image src={atsu} alt="Atsueigo" />
      </div>
      <Content>
        <Name>
          ATSU
          <YouTube
            href="https://www.youtube.com/channel/UCgeaC4OEk0t54m2hWQtjjIw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} alt="Atsueigo" />
          </YouTube>
        </Name>
        <TagList>
          <Tag>IELTS Academic 8.5</Tag>
          <Tag>実用英語技能検定１級</Tag>
          <Tag>TOEIC 990点満点</Tag>
          <Tag>TOEFL iBT 114点</Tag>
          <Tag>米国公認会計士（ワシントン）</Tag>
          <Tag>豪州勅許会計士</Tag>
          <Tag>オーストラリア永住権保持</Tag>
        </TagList>
        <Description>
          日本生まれ日本育ち。大学受験勉強をきっかけに本格的に英語学習を開始。大学院留学のため渡豪し、初の海外長期滞在を経験。オーストラリア国立大学（世界大学ランキング20位）会計学修士を成績優秀（Distinction）で修了。同年よりオーストラリアメルボルンにて世界４大会計事務所の１つに勤務し、アメリカ、イギリス、日系など幅広いグローバル案件の監査業務に従事している。
        </Description>
      </Content>
    </Inner>
  </Container>
);

export default Profile;
