import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Divider, TextField as MuiTextField, Button } from "@material-ui/core";
import { PlayCircleOutline, Devices, AllInclusive } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import media from "utils/media";
import secondsToHms from "utils/secondsToHms";
import numberWithComma from "utils/numberWithComma";
import PurchaseButton from "components/PurchaseButton";
import couponActions from "redux/actions/coupon";
import { CouponPrice } from "redux/reducers/skus";
import { Rating } from "components/Rating";
import Modal from "components/Modal";
import { SocialShare } from "components/SocialShare";
import useModal from "hooks/useModal";
import useQuery from "hooks/useQuery";

const Container = styled.div`
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;

  padding: ${({ theme: { spacing } }) => spacing(4)}px;
  > * {
    margin-bottom: ${({ theme: { spacing } }) => spacing(3)}px;
  }

  margin-left: ${({ theme: { spacing } }) => spacing(3)}px;
  flex-basis: 33.333%;

  ${media.sp`
    margin-left: 0px;
    background-color: inherit;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
  `}
`;

const Title = styled.h1`
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 32px;
`;

const PriceContainer = styled.div`
  font-size: ${({ theme: { typography } }) => typography.pxToRem(32)};
`;

const GoodForTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 8px;
`;

const GoodForList = styled.ul`
  list-style: inside;
`;

const GoodFontListItem = styled.li`
  margin-bottom: 8px;
`;

const Tips = styled.ul`
  font-size: 1.3rem;
  color: #999;
`;

const TipsItem = styled.li`
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`;

const Tou = styled.a`
  text-decoration: underline;
`;

const GoodFor = ({ skuId }: { skuId: string }) => {
  const goodFors = useSelector((state) => {
    return state.skus[skuId]?.goodFors;
  });
  return (
    <div>
      <GoodForTitle>こんな人におすすめ</GoodForTitle>
      <GoodForList>
        {goodFors.map((goodFor) => (
          <GoodFontListItem key={goodFor}>{goodFor}</GoodFontListItem>
        ))}
      </GoodForList>
    </div>
  );
};

const CouponFieldContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

const TextField = styled(MuiTextField)`
  flex: 1;
`;

const Error = styled.p`
  font-size: 1.2rem;
  color: ${({ theme: { palette } }) => palette.error.main};
`

const CouponField = ({ skuId }: { skuId: string }) => {
  const dispatch = useDispatch();
  const queryCouponCode = useQuery().get('coupon_code') || "";
  const coupon = useSelector((state) => state.coupon);
  const [couponCode, setCouponCode] = useState(coupon || queryCouponCode);
  const [error, setError] = useState(null as null | string);
  const sku = useSelector((state) => {
    return state.skus[skuId];
  });
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCouponCode(e.target.value);
  };
  const onClick = () => {
    const lowerCaseCouponCode = couponCode?.toLowerCase() ?? null;
    dispatch(couponActions.setCoupon(lowerCaseCouponCode));

    if (lowerCaseCouponCode && sku.coupons[lowerCaseCouponCode]) {
      setError(null);
    } else {
      setError(
        "クーポンが存在しません。入力が合っているかもう一度ご確認下さい。"
      );
    }
  };

  useEffect(() => {
    if (queryCouponCode) {
      onClick()
    }
  }, [])

  return (
    <div>
      <CouponFieldContainer>
        <TextField
          variant="outlined"
          placeholder="クーポンを入力して下さい"
          onChange={onChange}
          value={couponCode}
        />
        <Button variant="contained" color="secondary" onClick={onClick}>
          適用
        </Button>
      </CouponFieldContainer>
      {error && <Error >{error}</Error>}
    </div>
  );
};

const Original = styled.span`
  text-decoration: line-through;
  font-size: ${({ theme: { typography } }) => typography.pxToRem(24)};
  color: #999;
`;

const DiscountRate = styled.span`
  font-size: ${({ theme: { typography } }) => typography.pxToRem(24)};
  color: #999;
`;

const Price = ({
  price,
  coupon,
}: {
  price: number;
  coupon: CouponPrice | null;
}) => {
  const discountedRate = coupon
    ? 100 - Math.floor((coupon.price / price) * 100)
    : 0;
  return (
    <>
      {coupon ? (
        <PriceContainer>
          ¥{numberWithComma(coupon.price)}{" "}
          <Original>¥{numberWithComma(price)}</Original>{" "}
          <DiscountRate>{discountedRate}% OFF</DiscountRate>{" "}
        </PriceContainer>
      ) : (
        <PriceContainer>¥{numberWithComma(price)}</PriceContainer>
      )}
    </>
  );
};

const Purchase = ({ skuId }: { skuId: string }) => {
  const { sku, amountTime } = useSelector((state) => {
    const sku = state.skus[skuId];
    const sections = Object.values(state.sections).filter(
      (sec) => sec.skuId === skuId
    );
    const lectures = _.flatMap(sections, (sec) => {
      return Object.values(state.lectures).filter(
        (lec) => lec.sectionId === sec.id
      );
    });
    const amountSeconds = lectures.reduce((a, c) => a + c.amountSeconds, 0);
    const amountTime = secondsToHms(amountSeconds);
    return { sku, amountTime };
  });
  const coupon = useSelector((state) => {
    const coupon = state.coupon;
    return coupon ? sku.coupons[coupon] : null;
  });
  const [{ show }, setShow] = useModal("socialShare");

  const onClickShare = () => {
    setShow(true);
  };

  return (
    <Container>
      <Title>{sku.title}</Title>
      <Rating
        value={sku.rating.value}
        count={sku.rating.count}
        sizeRatio={1.4}
      />
      <div>{sku.subTitle}</div>
      <Divider variant="middle" />
      <GoodFor skuId={skuId} />
      <Price price={sku.price} coupon={coupon} />
      <PurchaseButton skuId={skuId} />
      <div>
        支払いを完了することにより、これらの<Tou href="#">サービス規約</Tou>
        に同意したものとみなされます。
      </div>
      <Tips>
        <TipsItem>
          <PlayCircleOutline /> {amountTime}のオンデマンドビデオ
        </TipsItem>
        <TipsItem>
          <Devices /> モバイルとPCからアクセス
        </TipsItem>
        <TipsItem>
          <AllInclusive /> 学習期間の制限なし
        </TipsItem>
      </Tips>
      <CouponField skuId={skuId} />
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        size="large"
        onClick={onClickShare}
      >
        シェア
      </Button>
      <Modal title="このページをシェアする" show={show} setShow={setShow}>
        <SocialShare link={window.location.href} />
      </Modal>
    </Container>
  );
};

export default Purchase;
