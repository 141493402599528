import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#333",
      light: "#DCEDC8",
    },
    secondary: {
      main: "#0e65f3",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    htmlFontSize: 10,
    fontSize: 14,
    body1: {
      fontSize: '1.4rem'
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 479,
      md: 768,
      lg: 959,
      xl: 1112,
    }
  }
});

export default theme;
