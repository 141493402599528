import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import useModal from "hooks/useModal";
import { useCourses } from "hooks/useCourses";
import { logout } from "redux/actions/account";
import { useOrderedSkus } from "hooks/useOrders";

import {
  TRANSACTION_LAW_URL,
  PRIVACY_POLICY_URL,
  TOS_URL,
  FAQ_URL,
} from "constants/index";

const Container = styled.div`
  width: 260px;
  padding: ${(props) => props.theme.spacing(2.5)}px;
  > * {
    margin-bottom: ${({ theme: { spacing } }) => spacing(1)}px;
  }
`;
const Heading = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
`;
const List = styled.ul`
  margin-bottom: 24px;
`;
const Item = styled.li`
  position: relative;
  font-size: 1.3rem;
  height: 30px;
  line-height: 30px;
  padding-left: 24px;
  &:before {
    content: "";
    display: block;
    height: 30px;
    width: 12px;
    position: absolute;
    top: -14px;
    left: 8px;
    border-bottom: dashed 1px #999;
    border-left: dashed 1px #999;
  }
  &:first-child {
    &:before {
      height: 22px;
      top: -6px;
    }
  }
  a {
    display: flex;
    align-items: center;
  }
  img {
    height: 24px;
    margin-right: 4px;
  }
  span {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const Authenticated = () => {
  const dispatch = useDispatch();
  const onClickLogout = () => {
    dispatch(logout());
  };
  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={onClickLogout}
    >
      ログアウト
    </Button>
  );
};

const Unauthenticated = () => {
  const [, setShowModal] = useModal("login");
  const onClickButton = (type: string) => () => {
    setShowModal(true, { type });
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={onClickButton("signin")}
      >
        ログイン
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={onClickButton("signup")}
      >
        新規登録
      </Button>
    </>
  );
};

const Sidebar = () => {
  const isLoggedIn = useSelector((state) => Boolean(state.account));
  const courses = useCourses();
  const orderedSkus = useOrderedSkus();

  return (
    <Container>
      {isLoggedIn ? <Authenticated /> : <Unauthenticated />}
      <hr />
      {orderedSkus.length > 0 && (
        <>
          <Heading>購入済みコース</Heading>
          <List>
            {orderedSkus
              .filter((sku) => sku.type === "course")
              .map((sku) => (
                <Item key={sku.id}>
                  <Link to={`/courses/${sku.id}/lectures`}>
                    <span>{sku.title}</span>
                  </Link>
                </Item>
              ))}
          </List>
        </>
      )}
      <Heading>Course Lineup</Heading>
      <List>
        {Object.entries(courses).map(([key, course]) => (
          <Item key={key}>
            <Link to={`/courses/${key}`}>
              <span>{course.title}</span>
            </Link>
          </Item>
        ))}
      </List>
      <Heading>その他</Heading>
      <List>
        <Item>
          <a href={TOS_URL}>
            <span>利用規約</span>
          </a>
        </Item>
        <Item>
          <a href={TRANSACTION_LAW_URL}>
            <span>特定商取引法に基づく表記</span>
          </a>
        </Item>
        <Item>
          <a href={PRIVACY_POLICY_URL}>
            <span>プライバシーポリシー</span>
          </a>
        </Item>
        <Item>
          <a target="_blank" rel="noopener noreferrer" href={FAQ_URL}>
            <span>お問い合わせ・よくある質問</span>
          </a>
        </Item>
      </List>
    </Container>
  );
};

export default Sidebar;
