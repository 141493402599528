import React from "react";
import styled from "styled-components";
import media from "utils/media";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
  ${media.xs`
    padding: 32px 0;
  `}
`;
const Inner = styled.div`
  width: 100%;
  max-width: 1020px;
  padding: 16px;
`;
type Props = {
  style?: {};
  id?: string;
};
const Container: React.FC<Props> = ({ children, style, id }) => (
  <StyledContainer style={style} id={id}>
    <Inner>{children}</Inner>
  </StyledContainer>
);

export default Container;
