import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  course0PriceId,
  course0Coupon0PriceId,
  course0ProductId,
  course0Coupon2PriceId,
} from "utils/config";

export type CouponPrice = {
  price: number;
  priceId: string;
};

type Coupons = {
  [key: string]: CouponPrice;
};

type Rating = {
  value: number;
  count: number;
};

export type Sku = {
  id: string;
  title: string;
  subTitle: string;
  description: string;
  producer: string;
  price: number;
  type: string;
  stripeSkuId: string;
  summaries: typeof summaries;
  goodFors: typeof goodFors;
  imageUrl: string;
  coupons: Coupons;
  rating: Rating;
  productId: string;
};

export type SkusState = {
  [key: string]: Sku;
};

const summaries = [
  "多くの学習者が抱える英語学習の問題点とは？",
  "英語学習の全体像を理解する",
  "英単語学習における最適な目標とは？",
  "文法習得に必要な学習時間と期間とは？",
  "音の変化の法則とは？",
  "リスニング聞き取り能力向上の3ステップ",
  "日本国内におけるスピーキング学習の基本",
  "エッセイライティングの書き方を身に付ける",
];

const goodFors = [
  "英語学習の全体像を明確に理解したい方",
  "効率的な英語学習方法を高速でマスターしたい方",
  "効果的な学習に必要な論理的思考力を高めたい方",
  "ATSUの英語学習アプローチを体系的に理解したい方",
];

const description = `本講座は英語学習の全体像を理解し、どのような戦略と戦術で学習を進めてゆくことが効果的で効率的であるかを紹介・説明する講座です。
英語学習を学習分野別に説明をすることで、総合的な英語学習力を高め、英検やTOEICといった試験資格突破だけでなく、
使える英語力強化を図ります。日本国内の学習で英語力を高め、海外で米国・豪州の公認会計士としてキャリアを築いたATSUが、受講生のみなさんの学習を強力にナビゲートします。

Chapter 1 英語学習法講座の意義　Why Should We Learn How to Study（約27分）
・なぜ英語を学ぶ前に「英語学習法」を学ぶのか？
・多くの学習者が抱える英語学習の問題点とは？
・Distinctionで学ぶビジネス表現（×２）

Chapter 2英語学習の全体像　Overview of English Studies（約26分）
・英語学習の全体像を理解する
・英語とは何かを理解する
・英語の構成要素を理解する
・単語について理解する
・文法について理解する
・発音について理解する
・英語を「使う」とはどういうこと？
・リーディングのプロセスについて理解する
・リスニングのプロセスについて理解する
・スピーキングのプロセスについて理解する
・ライティングのプロセスについて理解する
・Distinctionで学ぶビジネス表現（×２）

Chapter 3英単語の学習法Vocabulary（約34分）
・英単語の学習法を学ぶ意義とは？
・英語学習で必要な知識量とは？
・トップダウンアプローチによる英単語学習法
・英語学習の目的を明確化する
・目的と目標の違いを理解する
・英単語学習における最適な目標とは？
・英単語学習における最適な戦略とは？
・英単語学習における最適な戦術とは？
・戦略と戦術の違いを理解する
・目的、目標、戦略、戦術の関係性
・Distinctionで学ぶビジネス表現（×２）

Chapter 4英文法の学習法Grammar（約31分）
・英文法の学習法を学ぶ意義とは？
・文法学習の全体像を理解する
・文法習得に必要な学習時間と期間とは？
・社会人が1日平均3時間の学習を確保するには？
・文法学習の目標を設定する
・文法学習の戦略を立案する
・文法学習の戦術を立案する
・Distinctionで学ぶビジネス表現（×２）

Chapter 5発音の学習法Pronunciation（約28分）
・発音の学習法を学ぶ意義とは？
・発音学習の全体像を理解する
・各英単語の発音を理解する重要性
・音の変化の法則とは？
・英語っぽさとは？
・Distinctionで学ぶビジネス表現（×２）

Chapter 6リーディングの学習法Reading（約17分）
・リーディング学習の全体像
・精読とは？
・多読とは？
・精読と多読の優先順位を考える
・精読のやり方を理解する
・多読のやり方を理解する
・Distinctionで学ぶビジネス表現（×２）

Chapter 7リスニングの学習法Listening（約22分）
・リスニングとは何か？
・音が聞き取れない理由とは？
・リスニング聞き取り能力向上の3ステップ
・シャドーイングのやり方
・聞き取れるまでの流れを理解する
・音の変化～Tの音について解説
・Distinctionで学ぶビジネス表現（×２）

Chapter 8スピーキングの学習法① Speaking 1（約22分）
・スピーキング能力の構成要素とは？
・日本国内におけるスピーキング学習の基本
・独り言とは？
・独り言のやり方 – 2つの戦略
・Distinctionで学ぶビジネス表現（×２）

Chapter 9スピーキングの学習法② Speaking 2（約14分）
・オンライン英会話の戦略
・Distinctionで学ぶビジネス表現（×２）

Chapter 10ライティングの学習法 Writing（約31分）
・ライティング能力とは？
・エッセイライティングの書き方を身に付ける
・エッセイ構成の基本
・イントロダクションの書き方
・ボディの書き方
・コンクルージョンの書き方
・アイディアが思いつかないときはどうする？
・Distinctionで学ぶビジネス表現（×２）

`;

const coupons = {
  opensale: {
    price: 5980,
    priceId: course0Coupon0PriceId,
  },
  "atsueigo.com": {
    price: 7686,
    priceId: course0Coupon2PriceId,
  },
};

const initialState: SkusState = {
  "0": {
    id: "0",
    title: "論理的に英語を考える 英語学習法講座",
    subTitle:
      "効率的に英語学習を進めるには論理に基づいたムダのない学習アプローチが不可欠です。最短距離で英語学習を進めるための土台を、一緒に築き上げましょう！",
    producer: "Atsu",
    price: 10980,
    type: "course",
    productId: course0ProductId,
    stripeSkuId: course0PriceId,
    goodFors,
    summaries,
    description,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/atsueigo-57ccf.appspot.com/o/images%2Ftop_mobile.jpg?alt=media&token=48dd79f1-d169-481c-8f6c-d5799c778810",
    coupons,
    rating: {
      value: 4.6,
      count: 65,
    },
  },
};

const coursesReducer = reducerWithInitialState(initialState)
  // .case(coursesActions.getCoursesAction.done, (state, payload) => {
  //   if (!payload?.result?.courses) return state;
  //   return { ...state, ...payload.result.courses };
  // })
  // .case(coursesActions.getSkuAction.done, (state, payload) => {
  //   if (!payload?.result?.sku) return state;
  //   return { ...state, [payload.result.sku.id]: payload.result.sku }
  // })
  .default((state) => state);

export default coursesReducer;
