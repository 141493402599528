import { reducerWithInitialState } from "typescript-fsa-reducers";

export type Lecture = {
  sectionId: string;
  index: number;
  videoUrl: string;
  amountSeconds: number;
  title: string;
  id: string;
};

export type LecturesState = {
  [key: string]: Lecture;
};

const initialState: LecturesState = {
  "0": {
    sectionId: "0",
    index: 0,
    videoUrl: "https://vimeo.com/374617732?playsinline=1",
    amountSeconds: 27 * 60,
    id: "0",
    title: "Chapter 1 英語学習法講座の意義　Why Should We Learn How to Study",
  },
  "1": {
    sectionId: "0",
    index: 1,
    videoUrl: "https://vimeo.com/375676001?playsinline=1",
    amountSeconds: 26 * 60,
    id: "1",
    title: "Chapter 2 英語学習の全体像　Overview of English Studies",
  },
  "2": {
    sectionId: "1",
    index: 2,
    videoUrl: "https://vimeo.com/377977973?playsinline=1",
    amountSeconds: 34 * 60,
    id: "2",
    title: "Chapter 3 英単語の学習法 Vocabulary",
  },
  "3": {
    sectionId: "1",
    index: 3,
    videoUrl: "https://vimeo.com/376731352?playsinline=1",
    amountSeconds: 31 * 60,
    id: "3",
    title: "Chapter 4 英文法の学習法 Grammar",
  },
  "4": {
    sectionId: "1",
    index: 4,
    videoUrl: "https://vimeo.com/378107867?playsinline=1",
    amountSeconds: 28 * 60,
    id: "4",
    title: "Chapter 5 発音の学習法 Pronunciation",
  },
  "5": {
    sectionId: "2",
    index: 5,
    videoUrl: "https://vimeo.com/378569898?playsinline=1",
    amountSeconds: 17 * 60,
    id: "5",
    title: "Chapter 6 リーディングの学習法 Reading",
  },
  "6": {
    sectionId: "2",
    index: 6,
    videoUrl: "https://vimeo.com/416877993?playsinline=1",
    amountSeconds: 22 * 60,
    id: "6",
    title: "Chapter 7リスニングの学習法 Listening",
  },
  "7": {
    sectionId: "3",
    index: 7,
    videoUrl: "https://vimeo.com/378820795?playsinline=1",
    amountSeconds: 22 * 60,
    id: "7",
    title: "Chapter 8 スピーキングの学習法① Speaking 1",
  },
  "8": {
    sectionId: "3",
    index: 8,
    videoUrl: "https://vimeo.com/379061652?playsinline=1",
    amountSeconds: 14 * 60,
    id: "8",
    title: "Chapter 9スピーキングの学習法② Speaking 2",
  },
  "9": {
    sectionId: "3",
    index: 9,
    videoUrl: "https://player.vimeo.com/video/437139069?playsinline=1",
    amountSeconds: 31 * 60,
    id: "9",
    title: "Chapter 10ライティングの学習法 Writing",
  },
};

const lecturesReducer = reducerWithInitialState(initialState).default(state => state);

export default lecturesReducer;
